* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.rce-container-mbox {
  flex-direction: column;
  min-width: 300px;
  display: block;
  overflow-x: hidden;
}

.rce-mbox-forward {
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 5px #a4a4a4;
}

.rce-mbox-forward-left {
  opacity: 0;
  visibility: hidden;
  display: flex;
  left: -50px;
}

.rce-mbox-forward-right {
  opacity: 0;
  visibility: hidden;
  display: flex;
  right: -50px;
}

.rce-mbox-reply-btn-left {
  opacity: 0;
  visibility: hidden;
  display: flex;
  left: -85px;
}

.rce-mbox-reply-btn-right {
  opacity: 0;
  visibility: hidden;
  display: flex;
  right: -85px;
}

.rce-container-mbox:hover .rce-mbox-forward-left, .rce-container-mbox:hover .rce-mbox-forward-right {
  opacity: 1;
  visibility: visible;
}

.rce-mbox-remove {
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 5px #a4a4a4;
}

.rce-mbox-remove-left {
  opacity: 0;
  visibility: hidden;
  display: flex;
  left: -120px;
}

.rce-mbox-remove-right {
  opacity: 0;
  visibility: hidden;
  display: flex;
  right: -120px;
}

.rce-container-mbox:hover .rce-mbox-remove-left, .rce-container-mbox:hover .rce-mbox-remove-right, .rce-container-mbox:hover .rce-mbox-reply-btn-left, .rce-container-mbox:hover .rce-mbox-reply-btn-right {
  opacity: 1;
  visibility: visible;
}

.rce-mbox {
  float: left;
  background: #fff;
  border-radius: 0 5px 5px;
  flex-direction: column;
  min-width: 140px;
  margin: 3px 5px 3px 20px;
  padding: 6px 9px 8px;
  position: relative;
  box-shadow: 1px 1px 1px 1px #0003;
}

.rce-mbox.message-focus {
  animation-name: message-box-default-focus;
  animation-duration: 1s;
  animation-iteration-count: 2;
}

@-webkit-keyframes message-box-default-focus {
  0% {
    background-color: #fff;
  }

  to {
    background-color: #dfdfdf;
  }
}

.rce-mbox-body {
  margin: 0;
  padding: 0;
  position: relative;
}

.rce-mbox.rce-mbox-right {
  float: right;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  margin-left: 5px;
  margin-right: 20px;
}

.rce-mbox.rce-mbox-right.message-focus {
  animation-name: message-box-right-focus;
  animation-duration: 1s;
  animation-iteration-count: 2;
}

@-webkit-keyframes message-box-right-focus {
  0% {
    background-color: #d4f1fb;
  }

  to {
    background-color: #b8dae6;
  }
}

.rce-mbox-text {
  word-break: break-word;
  font-size: 13.6px;
}

.rce-mbox-text-retracted {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  font-style: italic;
  display: flex;
}

.rce-mbox-text.rce-mbox-text-retracted svg {
  margin-right: 3px;
}

.rce-mbox-text-retracted.left {
  color: #555555b3 !important;
}

.rce-mbox-text-retracted.right {
  color: #efefefb3 !important;
}

.rce-mbox-text:after {
  content: "                          ";
}

.rce-mbox-time {
  color: #00000073;
  text-align: right;
  -webkit-user-select: none;
  user-select: none;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  display: flex;
  bottom: -5px;
  right: -4px;
}

.rce-mbox-time.non-copiable:before {
  content: attr(data-text);
}

.rce-mbox-time-block {
  color: #fff;
  background: linear-gradient(0deg, #00000054, #0000);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  margin-left: -6px;
  margin-right: -6px;
  padding-top: 5px;
  padding-bottom: 2px;
  padding-right: 3px;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
}

.rce-mbox--clear-padding {
  padding-bottom: 3px;
}

.rce-mbox.rce-mbox--clear-notch {
  border-radius: 5px !important;
}

.rce-mbox-right-notch {
  fill: #fff;
  filter: drop-shadow(2px 0 1px #0003);
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  right: -14px;
}

.rce-mbox-right-notch.message-focus {
  animation-name: message-right-notch-focus;
  animation-duration: 1s;
  animation-iteration-count: 2;
}

@-webkit-keyframes message-right-notch-focus {
  0% {
    fill: #d4f1fb;
  }

  to {
    fill: #b8dae6;
  }
}

.rce-mbox-left-notch {
  fill: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: -14px;
}

.rce-mbox-left-notch.message-focus {
  animation-name: message-left-notch-focus;
  animation-duration: 1s;
  animation-iteration-count: 2;
}

@-webkit-keyframes message-left-notch-focus {
  0% {
    fill: #fff;
  }

  to {
    fill: #dfdfdf;
  }
}

.rce-mbox-title {
  color: #4f81a1;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  margin: 0 0 8px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
}

.rce-mbox-title:hover {
  text-decoration: underline;
}

.rce-mbox-title--clear {
  margin-bottom: 5px;
}

.rce-mbox-status {
  height: 15px;
  margin-left: 3px;
  font-size: 15px;
}

.rce-mbox-title > .rce-avatar-container {
  margin-right: 5px;
}

.rce-mbox-photo {
  margin-top: -3px;
  margin-left: -6px;
  margin-right: -6px;
}

.rce-mbox-photo .rce-mbox-text {
  max-width: 300px;
  margin: auto;
  padding: 5px 0;
}

.rce-mbox-photo--img {
  border-radius: 5px;
  justify-content: center;
  max-height: 300px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rce-mbox-photo--img__block {
  background-color: #00000080;
  border-radius: 5px;
  display: flex;
  position: absolute;
  inset: 0;
}

.rce-mbox-photo--img img {
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
  min-height: 100px;
}

.rce-mbox-photo--img__block-item {
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin: auto;
}

.rce-mbox-photo--download {
  color: #efe;
  background: none;
  border: 1px solid #eee;
  border-radius: 100%;
  outline: none;
  justify-content: center;
  width: 100px;
  height: 100px;
  font-size: 3.2em;
  display: flex;
}

.rce-mbox-photo--download:hover {
  opacity: .7;
}

.rce-mbox-photo--download:active {
  opacity: .3;
}

.rce-mbox-photo--error {
  color: #eaeaea;
  background: none;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  display: flex;
}

.rce-mbox-file {
  padding-bottom: 13px;
}

.rce-mbox-file > button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #e9e9e9;
  border: none;
  border-radius: 5px;
  outline: none;
  align-items: center;
  max-width: 500px;
  min-height: 52px;
  margin-top: -3px;
  margin-left: -6px;
  margin-right: -6px;
  padding: 5px 0;
  display: flex;
}

.rce-mbox-file > button > * {
  padding: 0 10px;
}

.rce-mbox-file--icon {
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  display: flex;
}

.rce-mbox-file--size {
  max-width: 52px;
  margin-top: 3px;
  font-size: 10px;
}

.rce-mbox-file--size, .rce-mbox-file--text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rce-mbox-file--text {
  font-size: 13.6px;
}

.rce-mbox-file--buttons {
  align-items: center;
  font-size: 30px;
  display: flex;
}

.rce-mbox-file--buttons .rce-error-button {
  display: inherit;
}

.rce-mbox-file--loading {
  width: 40px;
  height: 40px;
  font-size: 15px;
}

.rce-container-smsg, .rce-smsg {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rce-smsg {
  float: left;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  max-width: 70%;
  margin: 5px 0;
  padding: 6px 9px 8px;
  position: relative;
  box-shadow: 1px 1px 1px 1px #0003;
}

.rce-smsg-text {
  text-align: center;
  font-size: 15px;
  display: inline-block;
}

.rce-mbox-location {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 150px;
  margin-top: -3px;
  margin-left: -6px;
  margin-right: -6px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rce-mbox-location-img {
  width: 100%;
}

.rce-mbox-location-text {
  width: 250px;
  margin-left: -6px;
  margin-right: -6px;
  padding: 5px 0;
}

.rce-mbox-spotify {
  margin-top: -2px;
  margin-left: -6px;
  margin-right: -6px;
}

.rce-mbox-reply, .rce-mbox-spotify {
  border-radius: 5px;
  display: flex;
  overflow: hidden;
}

.rce-mbox-reply {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #ececec;
  margin: -3px -6px 6px;
  padding: 0 0 0 5px;
  font-size: 12px;
  transition: all .2s;
  position: relative;
}

.rce-mbox-reply.rce-mbox-reply-border {
  border-left: 5px solid;
}

.rce-mbox-reply:hover {
  opacity: .85;
}

.rce-mbox-reply-left {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 3px 0;
  display: flex;
}

.rce-mbox-reply-owner {
  font-size: 13px;
}

.rce-mbox-reply-message {
  color: #5a5a5a;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
}

.rce-mbox-reply-right {
  width: 40px;
  height: 40px;
}

.rce-mbox-reply-right img {
  width: 100%;
}

.rce-mbox-reply-text {
  width: 250px;
  margin-left: -6px;
  margin-right: -6px;
  padding: 5px 0;
}

.rce-mbox-mtmg {
  place-content: center;
  min-width: 425px;
  max-width: 425px;
  padding-bottom: 13px;
  display: flex;
}

.rce-mtmg {
  float: left;
  background: #f5f5f5;
  border-radius: 2px;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
  display: flex;
  position: relative;
}

.rce-mtmg-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
}

.rce-mtmg-subject {
  text-align: start;
  padding: 5px 9px;
  font-size: 15px;
  display: inline-block;
}

.rce-mtmg-toogleItem {
  width: 100%;
  height: 100%;
}

.rce-mtmg-body {
  color: #fff;
  background: #6264a7;
  justify-content: space-between;
  height: 50px;
  font-size: 15px;
  display: flex;
}

.rce-mtmg-body:hover {
  opacity: .9;
}

.rce-mtmg-item {
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.rce-mtmg-item > svg {
  width: 23px;
  height: 23px;
}

.rce-mtmg-content {
  flex-direction: column;
  padding: 0 10px;
  display: flex;
}

.rce-mtmg-date {
  color: #cecece;
  font-size: 13px;
}

.rce-mtmg-body-bottom {
  color: #6264a7;
  cursor: pointer;
  flex-direction: row;
  padding: 9px;
  font-size: 13px;
  display: flex;
}

.rce-mtmg-bottom--tptitle {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rce-mtmg-body-bottom--bttitle > svg, .rce-mtmg-bottom--tptitle > svg {
  color: #6264a7a1;
}

.rce-mtmg-toogleContent {
  flex-direction: column;
  height: auto;
  min-height: 60px;
  display: none;
}

.rce-mtmg-toogleContent--click {
  display: flex;
}

.rce-mtmg-right-icon {
  cursor: pointer;
  height: 100%;
  right: 10px;
  background: none !important;
}

.rce-mtmg-body .rce-dropdown-container {
  height: 100%;
}

.rce-mtmg-right-icon > svg {
  width: 23px;
  height: 23px;
}

.rce-mitem {
  padding: 10px 8px;
  display: flex;
}

.rce-mitem-event:hover, .rce-mitem:hover {
  background: #ececec;
}

.rce-mitem-event {
  -webkit-user-select: none;
  user-select: none;
}

.rce-mitem-body {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.rce-mitem-body-content {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.rce-mitem-body--top {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  margin-bottom: 2px;
  display: flex;
}

.rce-mitem-body--top-title {
  color: #6264a7;
  text-transform: capitalize;
  padding: 0 15px 0 0;
  font-size: 15px;
  font-weight: 600;
}

.rce-mitem-body--top-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.rce-mitem-body--bottom-title {
  color: #252525;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 13px;
}

.rce-mitem-body--top-time {
  color: #0006;
  font-size: 12px;
}

.rce-mitem-bottom-body {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 8px;
  display: flex;
}

.rce-mitem-body.avatar {
  padding: 8px 10px 0 0;
}

.rce-mitem.avatar {
  padding: 8px 5px 0 0;
}

.rce-mitem.no-avatar {
  padding: 8px 10px 0 0;
}

.rce-mitem.no-avatar > svg {
  width: 19px;
  height: 19px;
}

.rce-mitem.avatar img {
  background: #ccc;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  border: none !important;
}

.rce-mitem-body.avatar > svg {
  width: 19px;
  height: 19px;
}

.rce-mitem-bottom-body-top {
  flex-direction: column;
  display: flex;
}

.rce-mitem-bottom-body-top-title > svg {
  padding: 0 7px 0 0;
}

.rce-mitem-avatar-content {
  display: flex;
  position: absolute;
  right: 10px;
}

.rce-mitem-avatar {
  padding: 0 3px 0 0;
  display: flex;
}

.rce-mitem-tooltip {
  display: inline;
  position: relative;
}

.rce-mitem-tooltip-text {
  margin: 5px;
}

.rce-mitem-tooltip-text:after {
  content: "";
  opacity: 0;
  border-bottom: 11px solid #444;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  transition: opacity .8s linear .2s;
  position: absolute;
  top: 29px;
  left: 15%;
}

.rce-mitem-tooltip[tooltip]:after {
  color: #fff;
  content: attr(tooltip);
  opacity: 0;
  z-index: 1;
  background: #444;
  border-radius: 8px;
  justify-content: center;
  min-width: 415px;
  max-width: 415px;
  padding: 5px;
  font-size: 14px;
  transition: opacity .8s linear .2s;
  display: flex;
  position: absolute;
  top: 40px;
  right: -13px;
}

.rce-mitem-tooltip-text:hover:after {
  opacity: 1;
  transition: opacity .8s linear;
}

.rce-mitem-tooltip[tooltip]:hover:after {
  opacity: 1;
  transition: opacity .8s linear .1s;
}

.rce-mitem-tooltip[tooltip]:hover .rce-mitem-tooltip-text:after {
  opacity: 1;
}

.rce-mitem-length {
  color: #fff;
  text-align: center;
  background: #e48989;
  border-radius: 50%;
  align-items: center;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: flex;
}

.rce-mitem-avatar img {
  background: #ccc;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: none !important;
}

.rce-mtmg-call-record {
  background: #eaeaea;
  width: 350px;
  height: 85%;
  margin-top: 11px;
}

.rce-mtmg-call-body {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.rce-mtmg-call-avatars {
  position: relative;
  width: 140px !important;
  height: 100px !important;
}

.rce-mtmg-call-avatars img {
  cursor: pointer;
  background: #ccc;
  width: 100% !important;
  height: 100% !important;
}

.rce-mtmg-call-body-title {
  flex-direction: column;
  display: flex;
  position: relative;
  top: 30px;
  left: 15px;
}

.rce-mtmg-call-body-bottom, .rce-mtmg-call-body-title > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 185px;
  overflow: hidden;
}

.rce-mtmg-call-body-bottom {
  color: #505050;
  font-size: 12px;
}

.rce-mtmg-record-time {
  color: #fff;
  background: #000000cf;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.rce-avatar-container {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.rce-avatar-container .rce-avatar {
  width: 100%;
  height: 100%;
}

.rce-avatar-container.flexible .rce-avatar {
  border-radius: unset !important;
  overflow: unset !important;
  width: 100% !important;
  height: auto !important;
}

.rce-avatar-container.default {
  width: 25px;
  height: 25px;
}

.rce-avatar-container.rounded {
  border-radius: 5px;
}

.rce-avatar-container.circle {
  border-radius: 100%;
}

.rce-avatar-container.xsmall {
  width: 30px;
  height: 30px;
}

.rce-avatar-container.small {
  width: 35px;
  height: 35px;
}

.rce-avatar-container.medium {
  width: 40px;
  height: 40px;
}

.rce-avatar-container.large {
  width: 45px;
  height: 45px;
}

.rce-avatar-container.xlarge {
  width: 55px;
  height: 55px;
}

@keyframes avatarLazy {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
}

.rce-avatar-lazy {
  animation: 2s ease-in-out infinite avatarLazy;
}

.rce-avatar-container.rce-citem-avatar-encircle-status {
  box-sizing: border-box;
  position: relative;
}

.rce-avatar-letter {
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 20px;
  display: flex;
}

.rce-avatar-letter-background {
  border-radius: 20px;
  width: 35px;
  height: 35px;
}

.rce-dropdown-container {
  position: relative;
}

.rce-dropdown {
  box-sizing: border-box;
  transform-origin: 0 0;
  z-index: 99999;
  background: #fff;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  padding: 8px 15px;
  display: none;
  position: absolute;
  transform: scale(0);
  box-shadow: 0 0 5px #a3a3a3;
}

.rce-dropdown-title {
  color: "#000";
  font-size: 14px;
  font-weight: 500;
}

.rce-dropdown.dropdown-show {
  animation: .2s forwards dropdown-scaling;
  display: flex;
}

@keyframes dropdown-scaling {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
    transform: scale(1.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.rce-dropdown.dropdown-hide {
  animation: .2s forwards dropdown-reverse-scaling;
  display: flex;
}

@keyframes dropdown-reverse-scaling {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: .5;
    transform: scale(1.1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

.rce-dropdown-open__nortwest {
  transform-origin: 0 0;
  margin-top: 5px;
  top: 100%;
  left: 0;
}

.rce-dropdown-open__norteast {
  transform-origin: 100% 0;
  margin-top: 5px;
  top: 100%;
  right: 0;
}

.rce-dropdown-open__southwest {
  transform-origin: 0 100%;
  margin-bottom: 5px;
  bottom: 100%;
  left: 0;
}

.rce-dropdown-open__southeast {
  transform-origin: 100% 100%;
  margin-bottom: 5px;
  bottom: 100%;
  right: 0;
}

.rce-dropdown ul {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.rce-dropdown ul li {
  box-sizing: border-box;
  color: #767676;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.rce-dropdown ul li:last-child {
  border: none;
}

.rce-dropdown ul li:hover a {
  color: #3a6d8c;
}

.rce-dropdown.fade {
  opacity: 0;
  animation: .5s forwards dropdown-fade;
  transform: scale(1);
}

@keyframes dropdown-fade {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rce-button {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #3979aa;
  border: none;
  border-radius: 5px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  padding: 8px;
  font-size: 12px;
  transition: all .15s;
  position: relative;
}

.rce-button, .rce-button-icon--container {
  align-items: center;
  display: flex;
}

.rce-button:hover {
  opacity: .8;
}

.rce-button:active {
  opacity: .6;
}

.rce-button.outline {
  color: #3979aa;
  border: 1px solid #3979aa;
  background: none !important;
}

.rce-button.outline:hover {
  opacity: .6;
}

.rce-button.outline:active {
  opacity: .3;
}

.rce-button.transparent {
  background: none !important;
}

.rce-button.transparent:hover {
  opacity: .6;
}

.rce-button.transparent:active {
  opacity: .3;
}

.rce-button-icon {
  padding: 0 3px;
  font-size: 18px;
  display: flex;
  position: relative;
}

.rce-button-badge {
  background: #f64b34;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 10px;
  display: flex;
  position: absolute;
  top: -7px;
  right: -7px;
}

.rce-button.circle {
  border: 1px solid #3979aa;
  border-radius: 100%;
  min-width: 35px;
  min-height: 35px;
}

.rce-mbox-video {
  margin-top: -3px;
  margin-left: -6px;
  margin-right: -6px;
}

.rce-mbox-video.padding-time {
  padding-bottom: 12px;
}

.rce-mbox-video .rce-mbox-text {
  max-width: 300px;
  margin: auto;
  padding: 5px 0;
}

.rce-mbox-video--video {
  border-radius: 5px;
  justify-content: center;
  max-height: 500px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rce-mbox-video--video__block {
  background-color: #00000080;
  border-radius: 5px;
  display: flex;
  position: absolute;
  inset: 0;
}

.rce-mbox-video--video img {
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
  min-height: 100px;
}

.rce-mbox-video--video video {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}

.rce-mbox-video--video__block-item {
  cursor: pointer;
  width: 100px;
  height: 100px;
  margin: auto;
}

.rce-mbox-video--download {
  color: #efe;
  background: none;
  border: 1px solid #eee;
  border-radius: 100%;
  outline: none;
  justify-content: center;
  width: 100px;
  height: 100px;
  font-size: 3.2em;
  display: flex;
}

.rce-mbox-video--download:hover {
  opacity: .7;
}

.rce-mbox-video--download:active {
  opacity: .3;
}

.rce-mbox-video--error {
  color: #eaeaea;
  background: none;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  display: flex;
}

.rce-mbox-audio {
  max-width: 300px;
  padding-bottom: 10px;
}

audio:focus {
  outline: none;
}

.rce-mtlink {
  min-width: 400px;
  padding-bottom: 15px;
}

.rce-mtlink-content {
  background-color: #e2dfec;
  border-radius: 4px;
  justify-content: space-between;
  padding: 10px;
}

.rce-mtlink-content, .rce-mtlink-item {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.rce-mtlink-item {
  color: #6c687c;
  -webkit-user-select: none;
  user-select: none;
  justify-content: flex-start;
  min-width: 240px;
  max-width: 100%;
}

.rce-mtlink-title {
  color: #07030a;
  padding-left: 7px;
  font-size: 15px;
}

.rce-mtlink-btn {
  background: #ededed;
  border-radius: 4px;
  margin-left: 5px;
  display: flex;
}

.rce-mtlink-btn-content {
  cursor: pointer;
  border-right: 1px solid;
  padding: 4px;
}

.rce-mtlink-btn-content:last-child {
  border-right: none;
}

.rce-container-citem {
  flex-direction: column;
  min-width: 240px;
  display: block;
  overflow: hidden;
}

.rce-citem, .rce-container-citem.rce-citem-status-encircle {
  position: relative;
}

.rce-citem {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  flex-direction: row;
  min-width: 240px;
  max-width: 100%;
  height: 72px;
  display: flex;
  overflow: hidden;
}

.rce-citem:hover {
  background: #f9f9f9;
}

.rce-citem-avatar {
  padding: 0 15px 0 13px;
  position: relative;
}

.rce-citem-avatar, .rce-citem-status {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rce-citem-status {
  background: #ccc;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.rce-citem-avatar.rce-citem-status-encircle .rce-citem-status {
  background: none;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
  inset: 0;
}

.rce-citem-avatar img {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #ccc;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  font-size: 10px;
  line-height: 50px;
  overflow: hidden;
  border: none !important;
}

.rce-citem-body {
  border-bottom: 1px solid #0000000d;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-right: 15px;
  display: flex;
  overflow: hidden;
}

.rce-citem-body--top {
  display: flex;
}

.rce-citem-body--bottom {
  margin-top: 4px;
  display: flex;
}

.rce-citem-body--bottom-title, .rce-citem-body--top-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

.rce-citem-body--top-title {
  font-size: 16px;
}

.rce-citem-body--bottom-title {
  color: #555;
  font-size: 15px;
}

.rce-citem-body--top-time {
  color: #0006;
  font-size: 12px;
}

.rce-citem-body--bottom-status {
  margin-left: 3px;
}

.rce-citem-body--bottom-status span {
  color: #fff;
  text-align: center;
  background: red;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 700;
}

.rce-citem-body--bottom-status span, .rce-citem-body--bottom-status-icon {
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
}

.rce-citem-body--bottom-status-icon {
  margin-left: 3px;
  position: relative;
}

.rce-citem-body--bottom-tools {
  opacity: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 0;
  transition: height .5s, opacity 1s;
  display: flex;
  position: absolute;
  left: -999px;
}

.rce-citem:hover .rce-citem-body--bottom-tools {
  opacity: 1;
  height: 100%;
  position: relative;
  left: 0;
}

.rce-citem-body--bottom-tools-item-hidden-hover {
  opacity: .3;
  height: 100%;
  transition: all .5s;
}

.rce-citem:hover .rce-citem-body--bottom-tools-item-hidden-hover {
  opacity: 0;
  height: 0;
  transition: all .5s;
  position: absolute;
  left: -999px;
}

.rce-citem-body--bottom-tools-item {
  width: 18px;
  height: 18px;
}

.rce-citem-body--bottom-tools-item svg {
  fill: #575757;
  width: 18px;
  height: 18px;
}

.rce-container-clist {
  display: block;
  overflow: auto;
}

.rce-container-mlist {
  display: flex;
  position: relative;
}

.rce-mlist {
  flex: 1;
  display: block;
  position: relative;
  overflow: auto;
}

.rce-mlist-down-button {
  color: #333;
  cursor: pointer;
  background: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: all .2s;
  display: flex;
  position: absolute;
  bottom: 15px;
  right: 10px;
  box-shadow: 0 1px 1px #0000000d, 0 2px 5px #0000001a;
}

.rce-mlist-down-button:hover {
  opacity: .7;
}

.rce-mlist-down-button--badge {
  color: #fff;
  text-align: center;
  background: red;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: -5px;
  right: -5px;
}

.rce-container-mtitem {
  display: block;
}

.rce-container-mtitem, .rce-mtitem {
  flex-direction: column;
  min-width: 240px;
  overflow: hidden;
}

.rce-mtitem {
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border-bottom: 1px solid #0000000d;
  max-width: 100%;
  display: flex;
  position: relative;
}

.rce-mtitem:hover {
  background: #fbfbfb;
}

.rce-mtitem-top {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.rce-mtitem-subject {
  color: #333;
  text-overflow: ellipsis;
  max-height: 35px;
  margin-top: 5px;
  padding: 0 10px;
  font-size: 15px;
  overflow: hidden;
}

.rce-mtitem-body {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  display: flex;
  overflow: hidden;
}

.rce-mtitem-body--avatars {
  opacity: .7;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.rce-mtitem-body--functions {
  justify-content: flex-end;
  align-items: center;
  width: 70px;
  display: flex;
}

.rce-mtitem-footer {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 10px;
  display: flex;
}

.rce-mtitem-body--avatars .rce-avatar-container {
  border: 2px solid #fff;
  margin-left: -10px;
}

.rce-mtitem-body--avatars .rce-avatar-container:first-child {
  margin: 0;
}

.rce-mtitem-letter {
  color: #fff;
  background: #e48989;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rce-mtitem-button {
  background: #5ba7c5;
  transition: all .3s;
  display: flex;
}

.rce-mtitem-button, .rce-mtitem-closable {
  color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 3px;
  font-size: 25px;
}

.rce-mtitem-closable {
  background: #ff4a4f;
  margin-right: 7px;
  display: none;
}

.rce-mtitem:hover .rce-mtitem-closable {
  cursor: pointer;
  display: flex;
}

.rce-mtitem-share {
  color: #5ba7c5;
  justify-content: center;
  align-items: center;
  margin: -10px 0;
  font-size: 30px;
  transition: all .3s;
  display: flex;
  position: relative;
  left: -10px;
}

.rce-mtitem-button:hover, .rce-mtitem-share:hover {
  cursor: pointer;
  opacity: .8;
}

.rce-mtitem-date {
  color: #9f9f9f;
  font-size: 13px;
}

.rce-container-mtlist {
  display: block;
  overflow: auto;
}

.rce-container-input {
  box-sizing: border-box;
  background: #fff;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  display: flex;
}

.rce-input {
  box-sizing: border-box;
  color: #333;
  border: none;
  border-radius: 5px;
  outline: none;
  flex: 1;
  height: 40px;
  padding: 0 5px;
  font-size: 14px;
}

.rce-input-textarea {
  resize: none;
  height: 37px;
  padding: 10px 5px;
}

.rce-input-buttons {
  margin: 5px;
}

.rce-input-buttons, .rce-input-buttons > * {
  flex-direction: row;
  display: flex;
}

.rce-input-buttons .rce-button:nth-child(2n) {
  margin-left: 5px;
  margin-right: 5px;
}

.rce-input-buttons .rce-button:last-child {
  margin-right: 0;
}

.rce-left-padding {
  padding-left: 10px;
  padding-right: 0 !important;
}

.rce-right-padding {
  padding-right: 10px;
  padding-left: 0 !important;
}

.rce-input::placeholder {
  color: #afafaf;
}

.rce-navbar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.rce-navbar.light {
  background: #f4f4f4;
}

.rce-navbar.dark {
  background: #2f414c;
}

.rce-navbar-item {
  justify-content: flex-start;
  align-items: center;
}

.rce-navbar-item, .rce-navbar-item > * {
  flex-direction: row;
  display: flex;
}

.rce-navbar-item > * > * {
  margin-left: 5px;
}

.rce-sbar {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  padding: 10px;
  display: flex;
}

.rce-sbar.light {
  background: #f4f4f4;
}

.rce-sbar.dark {
  background: #2f414c;
}

.rce-sbar-item {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  display: flex;
}

.rce-sbar-item > * {
  flex-direction: column;
  display: flex;
}

.rce-sbar-item__center {
  margin: 15px 0;
}

.rce-popup-wrapper {
  z-index: 2147483647;
  background: #ffffffb3;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: fixed;
  inset: 0;
}

.rce-popup {
  box-sizing: border-box;
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 400px;
  min-height: 100px;
  padding: 0 10px;
  animation: .4s forwards popup-scaling;
  display: flex;
  box-shadow: 0 0 25px -2px #4f4f4f;
}

@keyframes popup-scaling {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: .5;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.rce-popup-header {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  display: flex;
}

.rce-popup-content {
  box-sizing: border-box;
  padding: 8px;
  font-size: 14px;
}

.rce-popup-content * {
  margin: 0;
}

.rce-popup-footer {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 18px 8px;
  display: flex;
}

.rce-popup-footer > * {
  margin-left: 5px;
}

.react-vis-magic-css-import-rule {
  display: inherit;
}

.rv-treemap {
  font-size: 12px;
  position: relative;
}

.rv-treemap__leaf {
  position: absolute;
  overflow: hidden;
}

.rv-treemap__leaf--circle {
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rv-treemap__leaf__content {
  text-overflow: ellipsis;
  padding: 10px;
  overflow: hidden;
}

.rv-xy-plot {
  color: #c3c3c3;
  position: relative;
}

.rv-xy-plot canvas {
  pointer-events: none;
}

.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute;
}

.rv-xy-plot__inner {
  display: block;
}

.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9;
}

.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9;
}

.rv-xy-plot__axis__tick__text, .rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px;
}

.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9;
}

.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9;
}

.rv-xy-plot__series, .rv-xy-plot__series path {
  pointer-events: all;
}

.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
}

.rv-crosshair {
  pointer-events: none;
  font-size: 11px;
  position: absolute;
}

.rv-crosshair__line {
  background: #47d3d9;
  width: 1px;
}

.rv-crosshair__inner {
  text-align: left;
  position: absolute;
  top: 0;
}

.rv-crosshair__inner__content {
  color: #fff;
  background: #3a3a48;
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  box-shadow: 0 2px 4px #00000080;
}

.rv-crosshair__inner--left {
  right: 4px;
}

.rv-crosshair__inner--right {
  left: 4px;
}

.rv-crosshair__title {
  white-space: nowrap;
  font-weight: bold;
}

.rv-crosshair__item {
  white-space: nowrap;
}

.rv-hint {
  pointer-events: none;
  position: absolute;
}

.rv-hint__content {
  color: #fff;
  text-align: left;
  white-space: nowrap;
  background: #3a3a48;
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  box-shadow: 0 2px 4px #00000080;
}

.rv-discrete-color-legend {
  box-sizing: border-box;
  font-size: 12px;
  overflow-y: auto;
}

.rv-discrete-color-legend.horizontal {
  white-space: nowrap;
}

.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px;
}

.rv-discrete-color-legend-item.horizontal {
  display: inline-block;
}

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block;
}

.rv-discrete-color-legend-item__color {
  vertical-align: middle;
  display: inline-block;
  overflow: visible;
}

.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px;
}

.rv-discrete-color-legend-item__title {
  margin-left: 10px;
}

.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8;
}

.rv-discrete-color-legend-item.clickable {
  cursor: pointer;
}

.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9;
}

.rv-search-wrapper {
  flex-direction: column;
  display: flex;
}

.rv-search-wrapper__form {
  flex: 0;
}

.rv-search-wrapper__form__input {
  color: #a6a6a5;
  box-sizing: border-box;
  border: 1px solid #e5e5e4;
  border-radius: 2px;
  outline: 0;
  width: 100%;
  margin: 0 0 9px;
  padding: 7px 10px;
  font-size: 12px;
}

.rv-search-wrapper__contents {
  flex: 1;
  overflow: auto;
}

.rv-continuous-color-legend {
  font-size: 12px;
}

.rv-continuous-color-legend .rv-gradient {
  border-radius: 2px;
  height: 4px;
  margin-bottom: 5px;
}

.rv-continuous-size-legend {
  font-size: 12px;
}

.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  width: 100%;
  margin-bottom: 5px;
  overflow: hidden;
}

.rv-continuous-size-legend .rv-bubble {
  vertical-align: bottom;
  background: #d8d9dc;
  display: inline-block;
}

.rv-continuous-size-legend .rv-spacer {
  width: 100%;
  font-size: 0;
  line-height: 0;
  display: inline-block;
}

.rv-legend-titles {
  height: 16px;
  position: relative;
}

.rv-legend-titles__left, .rv-legend-titles__right, .rv-legend-titles__center {
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
}

.rv-legend-titles__center {
  text-align: center;
  width: 100%;
  display: block;
}

.rv-legend-titles__right {
  right: 0;
}

.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none;
}
/*# sourceMappingURL=index.97fd2f2b.css.map */
